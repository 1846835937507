import { ReactNode } from "react";
import { Link } from "remix";
import styles from "~/styles/components/display/layout/mobile-nav-dropdown.css";

MobileNavDropdown.styles = styles;

import type { NavLinksArray } from "../../../constants";

type MobileNavDropdownProps = {
  navLinkItems: NavLinksArray;
};

export function MobileNavDropdown({ navLinkItems }: MobileNavDropdownProps) {
  return (
    <>
      <ul className="mobile-nav-link-list-wrapper">
        {navLinkItems.map((link) => {
          return (
            <MobileNavLinkItem
              key={link.text}
              text={link.text}
              icon={link.icon}
              href={link.href}
            />
          );
        })}
      </ul>
      <span className="block h-16"></span>
    </>
  );
}

type MobileNavLinkItemProps = {
  text: string;
  href: string;
  icon?: ReactNode;
};

function MobileNavLinkItem({ text, href, icon }: MobileNavLinkItemProps) {
  return (
    <li className="mobile-nav-link-wrapper">
      <Link prefetch="intent" className="link-item-link-element" to={href}>
        <div className="link-item-content-wrapper">
          <div data-link-type={text} className="icon-wrapper">
            {icon}
          </div>
          <span className="link-text">{text}</span>
        </div>
      </Link>
    </li>
  );
}
