export function ServiceWrenchIcon({
  fill = "black",
  className,
}: {
  fill?: string;
  className?: string;
}) {
  return (
    <svg
      className={className ? " " + className : ""}
      viewBox="0 0 451 451"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      aria-hidden="true"
      width="100%"
    >
      <path
        d="M392.444 332.286c17.31 17.305 17.31 45.394.009 62.704-17.325 17.305-45.403 17.305-62.722 0l-86.046-86.064c-26.838 6.004-56.11-1.408-76.95-22.256-24.048-24.046-29.182-58.063-19.916-85.398.831-2.405 4.413-2.596 5.686-1.318 0 0 49.757 49.769 49.782 49.781 6.721 6.725 17.684 6.737 24.412.004l20.577-20.58c6.719-6.732 6.71-17.682.023-24.42l-49.781-49.729c-1.271-1.272-1.094-4.867 1.316-5.685 27.347-9.218 61.261-4.089 85.299 19.961 20.848 20.835 28.255 50.097 22.257 76.925l86.054 86.075Zm47.923-142.95-40.568-6.34a178.473 178.473 0 0 0-21.364-51.665L403.6 96.843c3.174-4.335 2.656-10.987-1.145-14.779l-34.509-34.509c-3.793-3.798-10.444-4.31-14.78-1.151l-34.493 25.167a178.656 178.656 0 0 0-51.656-21.36l-6.338-40.568C259.838 4.342 254.772 0 249.409 0h-48.805c-5.363 0-10.428 4.341-11.268 9.643l-6.34 40.568a178.63 178.63 0 0 0-51.665 21.364L96.848 46.409c-4.336-3.164-10.988-2.652-14.78 1.146L47.559 82.064c-3.802 3.796-4.319 10.448-1.146 14.779l25.168 34.493a178.796 178.796 0 0 0-21.374 51.665l-40.551 6.335c-5.311.83-9.656 5.9-9.656 11.267v48.801c0 5.366 4.345 10.437 9.656 11.263l40.551 6.34a178.772 178.772 0 0 0 21.374 51.661l-25.168 34.49c-3.173 4.333-2.656 10.993 1.146 14.787l34.509 34.504c3.792 3.792 10.444 4.309 14.78 1.144l34.483-25.167a178.665 178.665 0 0 0 51.674 21.37l6.33 40.563c.84 5.302 5.904 9.642 11.268 9.642h48.805c5.362 0 10.429-4.339 11.27-9.642l6.338-40.563a178.441 178.441 0 0 0 18.556-5.533l-38.84-38.84c-7.085 1.173-14.309 1.934-21.726 1.934-73.099 0-132.356-59.258-132.356-132.355 0-73.098 59.257-132.356 132.356-132.356 73.097 0 132.355 59.257 132.355 132.356 0 8.95-.913 17.684-2.606 26.135l38.144 38.144a177.39 177.39 0 0 0 6.897-22.273l40.567-6.34c5.312-.83 9.646-5.896 9.646-11.263V200.6c.004-5.368-4.331-10.438-9.642-11.264Z"
        fill="inherit"
        fillRule="nonzero"
      />
    </svg>
  );
}
