export function ProductBoxIcon({
  fill = "black",
  className,
}: {
  fill?: string;
  className?: string;
}) {
  return (
    <svg
      className={className ? " " + className : ""}
      viewBox="0 0 101 69"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      aria-hidden="true"
      width="100%"
      height="100%"
    >
      <g fill="inherit" fillRule="nonzero">
        <path d="M86.889 58.671c0 3.62-2.36 6.12-6.08 6.52-7.29.8-14.57 1.63-21.86 2.43-2.68.29-5.36.54-8 .8h-1.14v.07c-1.45-.12-2.83-.19-4.21-.34-5.11-.56-10.22-1.15-15.33-1.73-4-.45-8-.88-11.94-1.37a6.189 6.189 0 0 1-5.19-6v-27.59h18.11a9.373 9.373 0 0 0 8.66-5.2c3.18-5.9 6.27-11.84 9.4-17.76.11-.21.24-.42.45-.79v.11c.21.35.32.54.43.73 3.18 5.93 6.32 11.87 9.55 17.78a9.524 9.524 0 0 0 8.78 5.18c5.82.05 11.63 0 17.45 0h.93v.95c-.007 8.733-.01 17.47-.01 26.21Z" />
        <path d="M83.709 27.781h-14a5.41 5.41 0 0 1-5.09-3.18 8311.426 8311.426 0 0 1-11.09-21.9l-.09-.18a1.812 1.812 0 0 1-.13-1.87 1.664 1.664 0 0 1 1.8-.58l14 1.79 14.34 1.85a7.004 7.004 0 0 1 5.27 3.37c3.61 5.92 7.18 11.87 10.76 17.81.145.228.269.47.37.72a1.458 1.458 0 0 1-1.16 2.12 5.388 5.388 0 0 1-1 0h-14l.02.05Zm-67.41 0h-14.3a1.852 1.852 0 0 1-1.85-.8 1.923 1.923 0 0 1 .32-2c3.6-6 7.19-12 10.83-17.92a6.863 6.863 0 0 1 5.18-3.3l15.52-2c4.3-.55 8.59-1.11 12.89-1.64.394-.04.792 0 1.17.12.588.182.928.808.76 1.4a4.559 4.559 0 0 1-.39 1.08c-3.7 7.33-7.43 14.72-11.18 22.09a5.157 5.157 0 0 1-4.75 3c-4.74 0-9.5-.03-14.2-.03Z" />
      </g>
    </svg>
  );
}
