import { ReactNode } from "react";
import { AiFillHome } from "react-icons/ai";

import {
  BusinessShopIcon,
  DealDollarIcon,
  ServiceWrenchIcon,
  ProductBoxIcon,
} from "../../../public/svg";

export type NavLink = {
  text: string;
  href: string;
  icon: ReactNode;
};

export type NavLinksArray = NavLink[];

export const navLinks: NavLinksArray = [
  {
    text: "Home",
    href: "/",
    icon: <AiFillHome />,
  },
  {
    text: "Businesses",
    href: "/businesses",
    icon: <BusinessShopIcon aria-hidden="true" />,
  },
  {
    text: "Products",
    href: "/products",
    icon: <ProductBoxIcon aria-hidden="true" />,
  },
  {
    text: "Services",
    href: "/services",
    icon: <ServiceWrenchIcon aria-hidden="true" />,
  },
  {
    text: "Hot Deals",
    href: "/deals",
    icon: <DealDollarIcon aria-hidden="true" />,
  },
];
