import { BiCopy } from "react-icons/bi";
import { Link } from "remix";

import styles from "~/styles/components/display/layout/footer.css";

Footer.styles = styles;

export function Footer() {
  return (
    <footer className="footer-wrapper">
      <div className="footer-content-container">
        <div className="footer-hey-fsj-copy">
          &copy; {new Date().getFullYear()} Hey FSJ
        </div>

        <ul className="footer-links-wrapper">
          {footerLinks.map((link, index) => {
            return (
              <li key={link.title}>
                <Link
                  prefetch="intent"
                  className={`footer-link${
                    index + 1 < footerLinks.length
                      ? " " + "footer-link-with-divider"
                      : ""
                  }`}
                  to={link.href}
                >
                  {link.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </footer>
  );
}

const footerLinks = [
  {
    title: "Contact",
    href: "/send-message",
  },
  {
    title: "Terms of Service",
    href: "/terms-of-service",
  },
  {
    title: "Privacy",
    href: "/privacy",
  },
];
