import { ReactNode } from "react";

import styles from "~/styles/components/display/layout/page-content-wrapper.css";
import pageCardContainerStyles from "~/styles/components/display/layout/page-card-container-wrapper.css";

PageContentWrapper.styles = styles;

export function PageContentWrapper({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={`page-content-wrapper${className ? " " + className : ""}`}>
      {children}
    </div>
  );
}

PageCardContainerWrapper.styles = pageCardContainerStyles;

export function PageCardContainerWrapper({
  children,
}: {
  children: ReactNode;
}) {
  return <div className="page-card-container-wrapper">{children}</div>;
}
