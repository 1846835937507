// @ts-nocheck
export function headerBodyScrollLock() {
  if (typeof window !== "undefined") {
    let scrollPosition = 0;

    var $navDropdownWrapper: Element,
      $menuCheckbox: Element,
      $linkItems: Element[],
      $searchCheckbox: Element;

    var bodyScrollLock = window.bodyScrollLock;

    $navDropdownWrapper = document.querySelector(".nav-dropdown-wrapper");
    $menuCheckbox = document.querySelector("#gn-menustate");
    $linkItems = document.querySelectorAll(".link-item-link-element");

    if ($menuCheckbox && $linkItems.length >= 1) {
      bodyScrollLock.enableBodyScroll($navDropdownWrapper);
      // console.log("$menuCheckbox.checked: ", $menuCheckbox.checked);

      $linkItems.forEach((linkItem) => {
        linkItem.addEventListener("click", () => {
          $menuCheckbox.checked = false;
          bodyScrollLock.clearAllBodyScrollLocks();
          // console.log("$menuCheckbox checked: ", $menuCheckbox.checked);
          // console.log("link clicked!!");
        });
      });

      $menuCheckbox.addEventListener("change", function () {
        if (this.checked) {
          // console.log("Checkbox checked!!");
          // We need to get the scroll position here because
          // body scroll lock will not reset the scroll position
          // when the menu closes, we will need to capture the
          // scroll position so that we can manually reset it later
          scrollPosition = window.pageYOffset;

          // Add setTimeout to avoid page shift. body scroll lock
          // sets the position to fixed to disable scrolling on
          // safari ios, unfortunately setting position fixed will
          // bump the body to the top of the page. The 350ms delay
          // lets the modal slide down before the page abruptly
          // shifts.
          setTimeout(() => {
            bodyScrollLock.disableBodyScroll($navDropdownWrapper);
          }, 350);
        } else {
          bodyScrollLock.clearAllBodyScrollLocks();
          bodyScrollLock.enableBodyScroll($navDropdownWrapper);
          // Reset the scroll position to where it was before the
          // dropdown was opened. Body scroll lock doesn't do
          // this automatically
          window.scrollTo(0, scrollPosition);
        }
      });

      $searchCheckbox = document.querySelector("#gn-search-menustate");

      $searchCheckbox.addEventListener("change", function () {
        if (this.checked) {
          // We need to get the scroll position here because
          // body scroll lock will not reset the scroll position
          // when the menu closes, we will need to capture the
          // scroll position so that we can manually reset it later
          scrollPosition = window.pageYOffset;

          // Add setTimeout to avoid page shift. body scroll lock
          // sets the position to fixed to disable scrolling on
          // safari ios, unfortunately setting position fixed will
          // bump the body to the top of the page. The 350ms delay
          // lets the modal slide down before the page abruptly
          // shifts.
          setTimeout(() => {
            bodyScrollLock.disableBodyScroll($navDropdownWrapper);
          }, 350);
        } else {
          bodyScrollLock.clearAllBodyScrollLocks();
          bodyScrollLock.enableBodyScroll($navDropdownWrapper);
          // Reset the scroll position to where it was before the
          // dropdown was opened. Body scroll lock doesn't do
          // this automatically
          window.scrollTo(0, scrollPosition);
        }
      });
    }
  }
}
