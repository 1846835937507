// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

import styles from "~/styles/components/display/page-content/block-headline.css";

BlockHeadline.styles = styles;

export function BlockHeadline({
  className,
  blockHeadlineData,
  hSize = 2,
}: {
  className?: string;
  blockHeadlineData: any[];
  hSize?: 1 | 2 | 3 | 4 | 5 | 6;
}) {
  const HeadlineBlockRenderer = (props: any) => {
    const { style = "normal" } = props.node;

    if (style === "normal") {
      return hSize === 1 ? (
        <h1 className={`block-headline${className ? " " + className : ""}`}>
          {props.children}
        </h1>
      ) : hSize === 2 ? (
        <h2 className={`block-headline${className ? " " + className : ""}`}>
          {props.children}
        </h2>
      ) : hSize === 3 ? (
        <h3 className={`block-headline${className ? " " + className : ""}`}>
          {props.children}
        </h3>
      ) : hSize === 4 ? (
        <h4 className={`block-headline${className ? " " + className : ""}`}>
          {props.children}
        </h4>
      ) : hSize === 5 ? (
        <h5 className={`block-headline${className ? " " + className : ""}`}>
          {props.children}
        </h5>
      ) : hSize === 6 ? (
        <h6 className={`block-headline${className ? " " + className : ""}`}>
          {props.children}
        </h6>
      ) : null;
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props);
  };

  return (
    <>
      <BlockContent
        blocks={blockHeadlineData[0]}
        serializers={{ types: { block: HeadlineBlockRenderer } }}
      />
      <span className="block h-4"></span>
    </>
  );
}
