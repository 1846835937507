import { ReactNode, useEffect, useState, useRef } from "react";
import { Link } from "remix";
import throttle from "lodash.throttle";

import styles from "~/styles/components/display/layout/tab-bar.css";
import { tabBarLinks } from "../../../constants/index";

TabBar.styles = styles;

export function TabBar() {
  const [isFooterVisible, setIsFooterVisible] = useState(true);
  let previousScrollValue: number | undefined = useRef(undefined).current;
  const TAB_BAR_HIDE_THRESHOLD = 440;

  useEffect(() => {
    function handleScroll() {
      let currentScroll = window.scrollY;

      if (typeof previousScrollValue !== "number") {
        previousScrollValue = currentScroll;
        return;
      }

      const direction = currentScroll > previousScrollValue ? "down" : "up";

      if (
        // isFooterVisible &&
        direction === "down" &&
        currentScroll > previousScrollValue + 20 &&
        currentScroll > TAB_BAR_HIDE_THRESHOLD
      ) {
        setIsFooterVisible(false);
      } else if (
        // !isFooterVisible &&
        direction === "up" &&
        currentScroll + 20 < previousScrollValue
      ) {
        setIsFooterVisible(true);
      }

      previousScrollValue = currentScroll;
    }

    const throttledScrollHandler = throttle(handleScroll, 250);

    window.addEventListener("scroll", throttledScrollHandler);

    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
    };
    // }, [isFooterVisible]);
  }, []);

  return (
    <nav className="tab-bar-wrapper" data-is-visible={isFooterVisible}>
      <ul className="tab-bar-links-wrapper">
        {tabBarLinks.map((link) => {
          return (
            <TabBarLink
              key={link.text}
              href={link.href}
              text={link.text}
              icon={link.icon}
            />
          );
        })}
      </ul>
    </nav>
  );
}

type TabBarLinkProps = {
  href: string;
  text: string;
  icon: ReactNode;
};

function TabBarLink({ href, text, icon }: TabBarLinkProps) {
  return (
    <li className="tb-link-wrapper" key={text}>
      <Link prefetch="intent" className="tb-link" to={href}>
        <div data-link-type={text} className="tb-link-icon-wrapper">
          {icon}
        </div>
        <div className="tb-link-text">{text}</div>
      </Link>
    </li>
  );
}
