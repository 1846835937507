import styles from "~/styles/components/display/layout/remix-image.css";
import { loadPageImages } from "~/src/utils/remix-image";

type RemixImageProps = {
  sanityUrl: string;
  lqipUrl?: string;
  svgColorFilter?:
    | "none"
    | "dark-blue"
    | "dark-red"
    | "dark-orange"
    | "dark-green"
    | "washed-out-blue-white"
    | "washed-out-red-white"
    | "washed-out-orange-white"
    | "washed-out-green-white";
  className?: string;
  alt: string;
  objectFit?: "cover" | "contain";
  objectPosition?: "left";
  loading?: "eager" | "lazy";
  showBlurUp?: boolean;
  showPlaceholderBackground?: boolean;
  imageInModal?: boolean;
  screenSizeToUseImage?: {
    mm?: string;
    ml?: string;
    mx?: string;
    ts?: string;
    tb?: string;
    lt?: string;
  };
};

RemixImage.styles = styles;

RemixImage.triggerImageLoading = triggerImageLoading;

export function RemixImage({
  loading = "lazy",
  sanityUrl,
  lqipUrl,
  className,
  alt,
  objectFit = "cover",
  svgColorFilter = "none",
  objectPosition,
  showPlaceholderBackground = false,
  showBlurUp = true,
  screenSizeToUseImage: screenSizeToUseImage = {
    mm: "360",
    ml: "425",
    mx: "565",
    ts: "768",
    tb: "1024",
    lt: "1280",
  },
}: RemixImageProps) {
  const mImageSrcset = `${sanityUrl}?w=359&q=60&auto=format&dpr=1 1x, ${sanityUrl}?w=359&q=50&auto=format&dpr=2 2x,${sanityUrl}?w=359&q=40&auto=format&dpr=3 3x`;
  const mmImageSrcset = `${sanityUrl}?w=424&q=60&auto=format&dpr=1 1x, ${sanityUrl}?w=424&q=50&auto=format&dpr=2 2x,${sanityUrl}?w=424&q=40&auto=format&dpr=3 3x`;
  const mlImageSrcset = `${sanityUrl}?w=564&q=60&auto=format&dpr=1 1x, ${sanityUrl}?w=564&q=50&auto=format&dpr=2 2x,${sanityUrl}?w=564&q=40&auto=format&dpr=3 3x`;
  const mxImageSrcset = `${sanityUrl}?w=767&q=60&auto=format&dpr=1 1x, ${sanityUrl}?w=767&q=50&auto=format&dpr=2 2x,${sanityUrl}?w=767&q=40&auto=format&dpr=3 3x`;
  const tsImageSrcset = `${sanityUrl}?w=1023&q=60&auto=format&dpr=1 1x, ${sanityUrl}?w=1023&q=50&auto=format&dpr=2 2x,${sanityUrl}?w=1023&q=40&auto=format&dpr=3 3x`;
  const tbImageSrcset = `${sanityUrl}?w=1279&q=60&auto=format&dpr=1 1x, ${sanityUrl}?w=1279&q=50&auto=format&dpr=2 2x,${sanityUrl}?w=1279&q=40&auto=format&dpr=3 3x`;
  const ltImageSrcset = `${sanityUrl}?w=2160&q=60&auto=format&dpr=1 1x, ${sanityUrl}?w=2160&q=50&auto=format&dpr=2 2x,${sanityUrl}?w=2160&q=40&auto=format&dpr=3 3x`;

  const defaultLqip = `${sanityUrl}?w=200&q=5&auto=format&dpr=1`;

  const sourceAndImageClass =
    loading === "lazy"
      ? "remix-image-source img-loading"
      : "remix-image-source";

  return (
    <>
      <div
        className={`remix-image-wrapper${className ? " " + className : ""}`}
        data-show-placeholder={showPlaceholderBackground}
        data-remix-image-loading={loading}
        data-svg-color-filter={svgColorFilter}
      >
        <picture className="remix-image-picture-wrapper">
          <source
            data-object-fit={objectFit}
            data-object-position={objectPosition}
            className={sourceAndImageClass}
            srcSet={loading === "eager" ? ltImageSrcset : ""}
            data-srcset={loading === "lazy" ? ltImageSrcset : ""}
            media={`(min-width: ${screenSizeToUseImage.lt || "1280"}px)`}
          />

          <source
            data-object-fit={objectFit}
            data-object-position={objectPosition}
            className={sourceAndImageClass}
            srcSet={loading === "eager" ? tbImageSrcset : ""}
            data-srcset={loading === "lazy" ? tbImageSrcset : ""}
            media={`(min-width: ${screenSizeToUseImage.tb || "1024"}px)`}
          />
          <source
            data-object-fit={objectFit}
            data-object-position={objectPosition}
            className={sourceAndImageClass}
            srcSet={loading === "eager" ? tsImageSrcset : ""}
            data-srcset={loading === "lazy" ? tsImageSrcset : ""}
            media={`(min-width: ${screenSizeToUseImage.ts || "768"}px)`}
          />
          <source
            data-object-fit={objectFit}
            data-object-position={objectPosition}
            className={sourceAndImageClass}
            srcSet={loading === "eager" ? mxImageSrcset : ""}
            data-srcset={loading === "lazy" ? mxImageSrcset : ""}
            media={`(min-width:${screenSizeToUseImage.mx || "565"}px)`}
          />
          <source
            data-object-fit={objectFit}
            data-object-position={objectPosition}
            className={sourceAndImageClass}
            srcSet={loading === "eager" ? mlImageSrcset : ""}
            data-srcset={loading === "lazy" ? mlImageSrcset : ""}
            media={`(min-width:${screenSizeToUseImage.ml || "425"}px)`}
          />
          <source
            data-object-fit={objectFit}
            data-object-position={objectPosition}
            className={sourceAndImageClass}
            srcSet={loading === "eager" ? mmImageSrcset : ""}
            data-srcset={loading === "lazy" ? mmImageSrcset : ""}
            media={`(min-width:${screenSizeToUseImage.mm || "360"}px)`}
          />

          <img
            data-object-fit={objectFit}
            data-object-position={objectPosition}
            className={sourceAndImageClass}
            src={lqipUrl ? lqipUrl : defaultLqip}
            srcSet={loading === "eager" ? mImageSrcset : ""}
            data-srcset={loading === "lazy" ? mImageSrcset : ""}
            data-svg-color-filter={svgColorFilter}
            loading={loading}
            alt={alt}
          />
        </picture>
        {showBlurUp && (
          <img
            data-object-fit={objectFit}
            data-object-position={objectPosition}
            className="ls-blur-up-img img-loading"
            src={lqipUrl ? lqipUrl : defaultLqip}
            alt={alt}
            data-img-loaded="false"
            data-svg-color-filter={svgColorFilter}
          />
        )}

        <noscript className="remix-image-noscript-fallback">
          <img
            data-object-fit={objectFit}
            data-object-position={objectPosition}
            className="remix-image-source"
            src={`url(${sanityUrl}?w=800&q=40&auto=format&dpr=1`}
            srcSet={tsImageSrcset}
            loading={loading}
            alt={alt}
            data-svg-color-filter={svgColorFilter}
          />
        </noscript>
        <div
          className="filter-wrapper"
          data-svg-color-filter={svgColorFilter}
        />
        <svg
          id="svgfilters"
          aria-hidden="true"
          style={{
            position: "absolute",
            width: 0,
            height: 0,
            overflow: "hidden",
          }}
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <defs>
            <filter
              id="dark-blue-sepia"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0
          0 0.1 0.1 0 0
          .2 .4 .3 0 0
          0 0 0 1 0"
                in="SourceGraphic"
                result="colormatrix"
              />
            </filter>
            <filter
              id="dark-red-sepia"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feColorMatrix
                type="matrix"
                values="0.200  0.600  0.900  0.000  0.000 
                0.000  0.400  0.000  0.000  0.000 
                0.000  0.000  0.300  0.000  0.000 
                0.000  0.000  0.000  1.000  0.000"
                in="SourceGraphic"
                result="colormatrix"
              />
            </filter>
            <filter
              id="dark-orange-sepia"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feColorMatrix
                type="matrix"
                values="0.200  0.400  0.900  0.000  0.000 
                0.000  0.600  0.000  0.000  0.000 
                0.000  0.000  0.300  0.000  0.000 
                0.000  0.000  0.000  1.000  0.000"
                in="SourceGraphic"
                result="colormatrix"
              />
            </filter>
            <filter
              id="dark-green-sepia"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feColorMatrix
                type="matrix"
                values="0.300  0.000  0.000  0.000  0.000 
                0.500  0.800  0.000  0.000  0.000 
                0.000  0.000  0.700  0.000  0.000 
                0.000  0.000  0.000  1.000  0.000"
                in="SourceGraphic"
                result="colormatrix"
              />
            </filter>
            <filter
              id="washed-out-blue-white"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feColorMatrix
                type="matrix"
                values="0.018  0.000  0.000  0.000  0.804 
                0.000  0.055  0.000  0.000  0.804 
                0.093  0.074  0.186  0.000  0.804 
                0.000  0.000  0.000  1.000  0.000"
                in="SourceGraphic"
                result="colormatrix"
              />
            </filter>
            <filter
              id="washed-out-red-white"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feColorMatrix
                type="matrix"
                values="0.099  0.066  0.000  0.000  0.827 
                0.000  0.000  0.000  0.000  0.827 
                0.000  0.000  0.000  0.000  0.827 
                0.000  0.000  0.000  1.000  0.0000"
                in="SourceGraphic"
                result="colormatrix"
              />
            </filter>
            <filter
              id="washed-out-orange-white"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feColorMatrix
                type="matrix"
                values="0.127  0.105  0.105  0.000  0.839 
                0.000  0.084  0.000  0.000  0.839 
                0.000  0.000  0.042  0.000  0.839 
                0.000  0.000  0.000  1.000  0.000"
                in="SourceGraphic"
                result="colormatrix"
              />
            </filter>
            <filter
              id="washed-out-green-white"
              x="-10%"
              y="-10%"
              width="120%"
              height="120%"
              filterUnits="objectBoundingBox"
              primitiveUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feColorMatrix
                type="matrix"
                values="0.057  0.000  0.000  0.000  0.849 
                0.000  0.191  0.000  0.000  0.849 
                0.000  0.000  0.153  0.000  0.849 
                0.000  0.000  0.000  1.000  0.000"
                in="SourceGraphic"
                result="colormatrix"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </>
  );
}

<svg xmlns="http://www.w3.org/2000/svg">
  <filter id="filter">
    <feColorMatrix
      type="matrix"
      values=" 0.300  0.000  0.000  0.000  0.000 
               0.500  0.800  0.000  0.000  0.000 
               0.000  0.000  0.700  0.000  0.000 
               0.000  0.000  0.000  1.000  0.000"
    ></feColorMatrix>
  </filter>
</svg>;

export function triggerImageLoading() {
  loadPageImages();
}
