import styles from "~/styles/components/display/star-rating.css";
import { FcGoogle } from "react-icons/fc";

StarRating.styles = styles;

export function StarRating({
  googleRating,
  googleRatingTotal,
  showGoogleIcon = true,
  showNumberOfRatings = true,
}: {
  googleRating: number;
  googleRatingTotal: number;
  showGoogleIcon?: boolean;
  showNumberOfRatings?: boolean;
}) {
  if (showNumberOfRatings) {
    if (
      !googleRating ||
      !googleRatingTotal ||
      googleRatingTotal < 1 ||
      (googleRating < 3 && googleRatingTotal < 3)
    ) {
      return (
        <>
          <span className="block h-2"></span>
          <div className="no-ratings-text">No Reviews Yet</div>
        </>
      );
    }
  }

  let starFillArray = createStarArray(googleRating);

  // console.log("starFillArray: ", starFillArray);

  return (
    <>
      <span className="block h-2"></span>
      <div className="star-rating-wrapper">
        {starFillArray.map((fillLevel, index) => {
          // console.log("fillLevel: ", fillLevel);

          return <Star key={index} fillLevel={fillLevel} />;
        })}
        <div className="number-of-ratings-wrapper">
          {showNumberOfRatings && (
            <div className="number-of-ratings-text">
              {googleRatingTotal} Review{googleRatingTotal > 1 ? "s" : ""}
            </div>
          )}
          {showGoogleIcon && (
            <div className="google-icon-wrapper">
              <FcGoogle />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

function Star({ fillLevel }: { fillLevel: string }) {
  return <div className="rating-star" data-fill-level={fillLevel}></div>;
}

function createStarArray(googleRating: number) {
  let starArray = [];

  // console.log("googleRating: ", googleRating);

  let ratingHasDecimal = googleRating - Math.floor(googleRating) !== 0;

  if (ratingHasDecimal) {
    let decimalRatingStringArray = googleRating
      .toFixed(1)
      .toString()
      .split(".");
    let decimal = "." + decimalRatingStringArray[1];
    let wholeNumber = Number(decimalRatingStringArray[0]);

    let counter = 5,
      decimalUsed = false;
    while (counter > 0) {
      if (wholeNumber > 0) {
        starArray.push("1");
        wholeNumber -= 1;
      } else if (wholeNumber < 1 && !decimalUsed) {
        starArray.push(decimal);
        decimalUsed = true;
      } else {
        starArray.push(".0");
      }
      counter -= 1;
    }
  } else {
    let counter = 5;
    while (counter > 0) {
      if (googleRating > 0) {
        starArray.push("1");
        googleRating -= 1;
      } else {
        starArray.push("0");
      }
      counter -= 1;
    }
  }

  // console.log("starArray: ", starArray);

  return starArray;
}
