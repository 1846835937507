import { ReactNode } from "react";
import { MdClose } from "react-icons/md";

import { RemixImage } from "../layout/remix-image";

import cardStyles from "../../../../styles/components/display/card/index.css";
import cardContentStyles from "../../../../styles/components/display/card/card-content.css";
import cardContainerStyles from "../../../../styles/components/display/card/card-container.css";
import cardTitleStyles from "../../../../styles/components/display/card/card-title-container.css";
import cardBottomLinkStyles from "~/styles/components/display/card/card-bottom-link-wrapper.css";

CardBottomLinkWrapper.styles = cardBottomLinkStyles;
Card.styles = cardStyles;
CardItemContent.styles = cardContentStyles;
CardContainer.styles = cardContainerStyles;

export function Card({
  children,
  className,
  addTopPadding = true,
  addTopAccent = true,
}: {
  children: ReactNode;
  className?: string;
  addTopPadding?: boolean;
  addTopAccent?: boolean;
}) {
  return (
    <div
      data-add-top-padding={addTopPadding}
      className={`card-wrapper${className ? " " + className : ""}`}
    >
      {addTopAccent && <div className="card-top-accent" />}
      {children}
    </div>
  );
}

export function CardItemContent({ children }: { children: ReactNode }) {
  return <div className="card-item-content-wrapper">{children}</div>;
}

export function CardContainer({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div className={`card-container${className ? " " + className : ""}`}>
      {children}
    </div>
  );
}

export function CardItemDivider({ className }: { className?: string }) {
  let mergedStyles = "card-content-item-divider";
  if (className) {
    mergedStyles = `${mergedStyles} ${className}`;
  }
  return <span className={mergedStyles}></span>;
}

export function CardBottomLinkWrapper({ children }: { children: ReactNode }) {
  return <div className="card-bottom-link-wrapper">{children}</div>;
}

export function CardItem({
  className,
  children,
  dataVariant,
}: {
  className?: string;
  children: ReactNode;
  dataVariant?: string;
}) {
  let mergedStyles = "card-item-wrapper";
  if (className) {
    mergedStyles = `${mergedStyles} ${className}`;
  }

  return (
    <div data-variant={dataVariant} className={mergedStyles}>
      {children}
    </div>
  );
}

export function CardTitleContainer({
  cardTitle,
  cardTitleIconUrl = "https://cdn.sanity.io/images/3kvhsh67/dev/78e41baba84296bb591990420c8ac04d482cbe48-640x448.svg",
  cardTitleIconAltText = "Icon alt text",
  closeModal,
}: {
  cardTitle: string;
  cardTitleIconUrl?: string;
  cardTitleIconAltText?: string;
  closeModal?: any;
}) {
  return (
    <CardItemContent>
      <div className="card-title-wrapper">
        <div className="card-title-text-wrapper">
          {cardTitleIconUrl && (
            <div className="title-icon-wrapper">
              <RemixImage
                loading="eager"
                objectFit="contain"
                screenSizeToUseImage={{
                  mm: "50000",
                  ml: "50000",
                  mx: "50000",
                  ts: "50000",
                  tb: "50000",
                  lt: "50000",
                }}
                sanityUrl={cardTitleIconUrl}
                alt={cardTitleIconAltText}
              />
            </div>
          )}
          <h6 className="card-title-text">{cardTitle}</h6>
        </div>
        <button
          className="close-modal-button-wrapper close-button"
          onClick={closeModal}
        >
          <MdClose aria-hidden="true" />
          <span className="visually-hidden">Close Modal</span>
        </button>
      </div>
    </CardItemContent>
  );
}

Card.links = [
  { rel: "stylesheet", href: cardStyles },
  { rel: "stylesheet", href: cardContentStyles },
  { rel: "stylesheet", href: cardContainerStyles },
  { rel: "stylesheet", href: cardTitleStyles },
];
