import { Children } from "react";
import { eagerlyLoadImages } from "./eagerload";
import { lazyLoadImages } from "./lazyload";

export function loadPageImages() {
  if (
    document.readyState === "complete" ||
    document.readyState === "interactive"
  ) {
    loadImages();
  } else {
    document.addEventListener("DOMContentLoaded", loadImages);
  }
}

function loadImages() {
  if (typeof document !== "undefined") {
    if (
      document.readyState === "complete" ||
      document.readyState === "interactive"
    ) {
      // Get images to eagerly/lazy load on page
      // The only elements that we care about are the
      // lqip placeholder <img /> and the <picture /> element
      // where we will load are images to
      // getImageElementsFromPage will generate these for us
      let { eagerLoadPictureAndLqipTuples, lazyLoadPictureAndLqipTuples } =
        getImageElementsFromPage(document);

      eagerlyLoadImages(eagerLoadPictureAndLqipTuples);
      lazyLoadImages(lazyLoadPictureAndLqipTuples);
    }
  }
}

function getImageElementsFromPage(document: Document) {
  // Search the page for image elements
  // Two types exist:
  // - Images to lazyload
  // - Images to load eagerly

  // Identify images to eagerly load
  let imagesToLoadEagerly: Element[] = [
    ...document.querySelectorAll("[data-remix-image-loading='eager']"),
  ];

  // Identify images to lazyload
  let imagesToLazyLoad = [
    ...document.querySelectorAll("[data-remix-image-loading='lazy']"),
  ];

  // Once we have the arrays for images to lazy/eager load,
  // we will want to unpack them. Their HTML structure is this:
  // <picture>
  // <noscript />
  // <picture /> Where the image will go when it loads
  // <img />  lqip blur-up placeholder
  // </picture>
  // All that we really care about is the lqip placeholder
  // and the picture where our image is loading to
  let lazyLoadPictureAndLqipTuples =
    generateArrayOfPictureAndLqipTuples(imagesToLazyLoad);
  let eagerLoadPictureAndLqipTuples =
    generateArrayOfPictureAndLqipTuples(imagesToLoadEagerly);

  return {
    eagerLoadPictureAndLqipTuples,
    lazyLoadPictureAndLqipTuples,
  };
}

function generateArrayOfPictureAndLqipTuples(imageComponents: Element[]) {
  let arrayOfLqipAndPictureElementTuples: Element[][] = [];
  imageComponents.forEach((image) => {
    if (image) {
      let imageChildren = [...image.children];
      imageChildren.forEach((childElement, index, array) => {
        if (childElement.nodeName === "PICTURE") {
          // @ts-ignore
          array[index] = [...childElement.children];
        }
      });

      arrayOfLqipAndPictureElementTuples.push(
        imageChildren.filter(
          (htmlElement) => htmlElement.nodeName !== "NOSCRIPT"
        )
      );
    }
  });

  return arrayOfLqipAndPictureElementTuples;
}
