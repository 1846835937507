import { ReactNode } from "react";

import sectionWrapperStyles from "~/styles/components/display/layout/section-wrapper.css";
import sectionContentWrapperStyles from "~/styles/components/display/layout/section-content-wrapper.css";

type SectionWrapperProps = {
  children: ReactNode;
  className?: string;
};

SectionWrapper.styles = sectionWrapperStyles;

export function SectionWrapper({ children, className }: SectionWrapperProps) {
  return (
    <section className={`section-wrapper${className ? " " + className : ""}`}>
      <div className="section-content-container">{children}</div>
    </section>
  );
}

SectionContentWrapper.styles = sectionContentWrapperStyles;

export function SectionContentWrapper({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={`section-content-wrapper${className ? " " + className : ""}`}
    >
      {children}
    </div>
  );
}
