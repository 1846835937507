import styles from "~/styles/components/display/page-content/pre-headline-text.css";

PreHeadlineText.styles = styles;
export function PreHeadlineText({
  children,
  className,
}: {
  children: string;
  className?: string;
}) {
  return (
    <>
      <div className={`pre-headline-text${className ? " " + className : ""}`}>
        {children}
      </div>
      <span className="block h-2"></span>
    </>
  );
}
