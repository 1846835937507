export function BusinessShopIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className ? " " + className : ""}
      viewBox="0 0 21 18"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      aria-hidden="true"
      width="100%"
      height="100%"
    >
      <path
        d="M20.523 4.17 18.311.527A1.084 1.084 0 0 0 17.387 0H3.612c-.375 0-.726.2-.924.527L.475 4.17C-.667 6.05.345 8.666 2.48 8.965a3.324 3.324 0 0 0 2.983-1.132A3.329 3.329 0 0 0 7.98 8.996c1.009 0 1.902-.457 2.516-1.163a3.329 3.329 0 0 0 2.516 1.163c1.01 0 1.903-.457 2.516-1.163a3.335 3.335 0 0 0 2.984 1.132c2.14-.295 3.157-2.911 2.011-4.795Zm-2.472 5.955a4.2 4.2 0 0 1-1.005-.134V13.5H3.953V9.991a4.385 4.385 0 0 1-1.006.134c-.204 0-.412-.014-.613-.042a4.17 4.17 0 0 1-.56-.127v6.919c0 .622.488 1.125 1.091 1.125H18.14c.604 0 1.091-.503 1.091-1.125V9.956a3.393 3.393 0 0 1-.56.127 4.604 4.604 0 0 1-.62.042Z"
        fill="inherit"
      />
    </svg>
  );
}
