export function PhoneIcon({
  fill = "black",
  className,
}: {
  fill?: string;
  className?: string;
}) {
  return (
    <svg
      className={className ? " " + className : ""}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      aria-hidden="true"
      width="100%"
    >
      <path
        d="M98.452 77.61c-4.313-7.73-19.483-14.96-28.134-15.11-8.65-.16-8.376 11.43-8.364 11.45-.002 0-10.525-2.81-22.16-14.45-11.636-11.63-14.45-22.16-14.451-22.16.024.01 11.612.29 11.456-8.36-.156-8.65-7.386-23.82-15.111-28.14C13.963-3.47-1.174 9.34.073 24.98 1.32 40.63 11.062 59.94 25.211 74.09 39.36 88.24 58.668 97.98 74.312 99.22c15.645 1.25 28.454-13.89 24.14-21.61Z"
        fill={fill}
        fillRule="nonzero"
      />
    </svg>
  );
}
