export function eagerlyLoadImages(lqipAndImageElementsTupleArray: Element[][]) {
  lqipAndImageElementsTupleArray.forEach((tuple) => {
    eagerlyLoadImage(tuple);
  });
}

function eagerlyLoadImage(lqipAndImageElementsTuple: Element[]) {
  let [eagerEls, lqipPlaceholder] = lqipAndImageElementsTuple;

  // @ts-ignore
  eagerEls.forEach((el) => {
    var src = el.getAttribute("data-src");
    var srcset = el.getAttribute("data-srcset");
    // [NOTE] Todd We shouldn't hit this if data-src was null, but monitoring
    //    says it happens sometimes, so ¯\_(ツ)_/¯
    if (src) {
      el.setAttribute("src", src);
    }
    if (srcset) {
      el.setAttribute("srcset", srcset);
    }
    el.removeAttribute("data-src");
    el.removeAttribute("data-srcset");

    if (el.nodeName === "IMG") {
      if (el.width > 50) {
        lqipPlaceholder.setAttribute("data-img-loaded", "true");
      } else {
        // @ts-ignore
        el.onload = async function () {
          let targetWidth = 5;
          function checkImageWidthIn300ms() {
            return new Promise((resolve) => {
              let checkImageInterval = setInterval(() => {
                // @ts-ignore
                let width = el.width;

                if (width > targetWidth) {
                  resolve("");
                  clearInterval(checkImageInterval);
                }
              }, 300);
            });
          }

          await checkImageWidthIn300ms();

          lqipPlaceholder.setAttribute("data-img-loaded", "true");
        };
      }
    }
  });
}
