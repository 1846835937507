export function lazyLoadImages(lqipAndImageElementsTupleArray: Element[][]) {
  lqipAndImageElementsTupleArray.forEach((tuple) => lazyLoadImage(tuple));
}

function lazyLoadImage(lqipAndImageElementsTuple: Element[]) {
  let [lazyEls, lqipPlaceholder] = lqipAndImageElementsTuple;

  function load(el: Element) {
    var src = el.getAttribute("data-src");
    var srcset = el.getAttribute("data-srcset");
    // [NOTE] Todd We shouldn't hit this if data-src was null, but monitoring
    //    says it happens sometimes, so ¯\_(ツ)_/¯
    if (src) {
      el.setAttribute("src", src);
    }
    if (srcset) {
      el.setAttribute("srcset", srcset);
    }
    el.removeAttribute("data-src");
    el.removeAttribute("data-srcset");

    if (el.nodeName === "IMG") {
      // @ts-ignore
      el.onload = async function () {
        let targetWidth = 5;
        function checkImageWidthIn300ms() {
          return new Promise((resolve) => {
            let checkImageInterval = setInterval(() => {
              // @ts-ignore
              let width = el.width;

              if (width > targetWidth) {
                resolve("");
                clearInterval(checkImageInterval);
              }
            }, 300);
          });
        }

        await checkImageWidthIn300ms();

        lqipPlaceholder.setAttribute("data-img-loaded", "true");
      };
    }
  }

  if ("IntersectionObserver" in window) {
    var lazyObserver = new IntersectionObserver(function (entries) {
      entries.forEach(function (entry) {
        if (entry.isIntersecting) {
          var el = entry.target;
          load(el);
          lazyObserver.unobserve(el);
        }
      });
    });
    // @ts-ignore
    lazyEls.forEach(function (el: Element) {
      if (el.tagName === "SCRIPT") {
        load(el);
      } else {
        lazyObserver.observe(el);
      }
    });
  } else {
    // @ts-ignore
    lazyEls.forEach(load);
  }
}
