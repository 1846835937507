import { ReactNode, useState, useEffect } from "react";
import { HeyFsjLogo } from "../../../../../public/svg/hey-fsj-fun-proxima";
import styles from "~/styles/components/display/layout/header.css";
import { Link } from "remix";
import { IoSearch } from "react-icons/io5";

import { MobileNavDropdown } from "./mobile-nav-dropdown";
import { navLinks } from "../../../constants/index";
import throttle from "lodash.throttle";

import {
  initializeBodyScrollLockLibrary,
  headerBodyScrollLock,
} from "../../../utils/body-scroll-lock";

Header.styles = styles;

Header.links = [
  { rel: "stylesheet", href: styles },
  { rel: "stylesheet", href: MobileNavDropdown.styles },
];

export function Header({ isHeaderVisible }: { isHeaderVisible: boolean }) {
  let [menuOpen, setMenuOpen] = useState(false);
  const [isHeaderSolidBackground, setIsHeaderSolidBackground] = useState(false);
  // const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  // let previousScrollValue: number | undefined = useRef(undefined).current;
  // // let isHeaderSolidBackground = useRef(true).current

  // const HEADER_HIDE_THRESHOLD = 440;

  // useEffect(() => {
  //   function handleScroll() {
  //     let currentScroll = window.scrollY;

  //     if (typeof previousScrollValue !== "number") {
  //       previousScrollValue = currentScroll;
  //       return;
  //     }

  //     const direction = currentScroll > previousScrollValue ? "down" : "up";

  //     if (
  //       // isHeaderVisible &&
  //       direction === "down" &&
  //       currentScroll > previousScrollValue + 20 &&
  //       currentScroll > HEADER_HIDE_THRESHOLD
  //     ) {
  //       setIsHeaderVisible(false);
  //     } else if (
  //       // !isHeaderVisible &&
  //       direction === "up"
  //     ) {
  //       if (
  //         window.innerWidth < 1100 &&
  //         currentScroll + 20 < previousScrollValue
  //       ) {
  //         setIsHeaderVisible(true);
  //       }

  //       if (
  //         window.innerWidth >= 1100 &&
  //         currentScroll < HEADER_HIDE_THRESHOLD &&
  //         currentScroll + 20 < previousScrollValue
  //       ) {
  //         setIsHeaderVisible(true);
  //       }
  //     }

  //     previousScrollValue = currentScroll;
  //   }

  //   const throttledScrollHandler = throttle(handleScroll, 250);

  //   window.addEventListener("scroll", throttledScrollHandler);

  //   return () => {
  //     window.removeEventListener("scroll", throttledScrollHandler);
  //   };
  // }, [isHeaderVisible]);

  useEffect(() => {
    setIsHeaderSolidBackground(false);

    function handleScroll() {
      let currentScroll = window.scrollY;

      if (window.innerWidth < 360) {
        if (currentScroll > 50) {
          setIsHeaderSolidBackground(true);
        } else {
          setIsHeaderSolidBackground(false);
        }
      } else if (window.innerWidth >= 360 && window.innerWidth < 1100) {
        if (currentScroll > 60) {
          setIsHeaderSolidBackground(true);
        } else {
          setIsHeaderSolidBackground(false);
        }
      } else if (window.innerWidth >= 1100) {
        if (currentScroll > 75) {
          setIsHeaderSolidBackground(true);
        } else {
          setIsHeaderSolidBackground(false);
        }
      }
    }

    const throttledScrollHandler = throttle(handleScroll, 100);

    window.addEventListener("scroll", throttledScrollHandler);

    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      initializeBodyScrollLockLibrary();

      // @ts-ignore
      headerBodyScrollLock();
    }
  }, []);

  var jsEnabled = false;
  if (typeof window !== "undefined") {
    jsEnabled = true;
  }

  return (
    <>
      <input
        className="gn-menustate"
        type="checkbox"
        id="gn-search-menustate"
        aria-hidden="true"
        tabIndex={-1}
      />
      <input
        className="gn-menustate"
        type="checkbox"
        id="gn-menustate"
        aria-hidden="true"
        tabIndex={-1}
      />

      <HeaderComponent
        className="gn-header"
        isHeaderVisible={isHeaderVisible}
        isHeaderSolidBackground={isHeaderSolidBackground}
      >
        <nav role="navigation" className="gn-header-wrapper">
          <div className="hidden lt:block">
            <Link prefetch="intent" onClick={() => setMenuOpen(false)} to="/">
              <span className="visually-hidden">Go to Homepage</span>
              <div className="gn-logo-wrapper">
                <HeyFsjLogo />
              </div>
            </Link>
          </div>
          <ul className="gn-mobile-nav-links-wrapper">
            <li className="gn-nav-link-item gn-nav-menu-link-item">
              <label
                className="gn-label"
                htmlFor="gn-menustate"
                aria-hidden="true"
                onClick={() => {
                  setMenuOpen(!menuOpen);
                }}
              >
                {/* <span className="gn-nav-link-bump gn-nav-menu-link-bump" />
                <span className="gn-icon-color-background gn-menu-icon-color-background" /> */}
                <div className="gn-menu-icon-wrapper">
                  <span className="menu-breadcrust menu-breadcrust-top" />
                  <span className="menu-breadcrust menu-breadcrust-middle" />
                  <span className="menu-breadcrust menu-breadcrust-bottom" />
                </div>
              </label>
              <a
                href="#gn-menustate"
                className="gn-menuanchor gn-menuanchor-open"
                role="button"
                onClick={(e) => {
                  e.preventDefault();
                  setMenuOpen(true);
                }}
              >
                <span className="gn-menuanchor-label visually-hidden">
                  Global Nav Open Menu
                </span>
              </a>
              <a
                href="#"
                className="gn-menuanchor gn-menuanchor-close"
                role="button"
                onClick={() => setMenuOpen(false)}
              >
                <span className="gn-menuanchor-label visually-hidden">
                  Global Nav Close Menu
                </span>
              </a>
            </li>
            <li>
              <Link prefetch="intent" onClick={() => setMenuOpen(false)} to="/">
                <span className="visually-hidden">Go to homepage</span>
                <div className="gn-logo-wrapper">
                  <HeyFsjLogo />
                </div>
              </Link>
            </li>

            <li className="gn-nav-link-item gn-nav-search-link-item">
              <Link to="/search" prefetch="intent">
                <IoSearch aria-hidden="true" />
                <span className="visually-hidden">Search</span>
              </Link>
            </li>
          </ul>
          <div className="gn-fs-nav-wrapper">
            <div className="gn-fullscreen-nav-links-wrapper">
              <ul className="gn-fullscreen-nav-link-list-wrapper">
                {navLinks.map((link) => {
                  return (
                    <FullscreenNavLink
                      key={link.href}
                      text={link.text}
                      href={link.href}
                      icon={link.icon}
                    />
                  );
                })}
                <li className="fs-nav-link">
                  <Link
                    prefetch="intent"
                    to="/search"
                    className="fs-nav-link-wrapper !text-lg dt:!text-xl dt:!-mt-0.5"
                  >
                    <span className="visually-hidden">Search Site</span>
                    <div className="fs-nav-link-icon-wrapper">
                      <IoSearch aria-hidden="true" />
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </HeaderComponent>

      <div className="nav-dropdown-wrapper">
        <MobileNavDropdown navLinkItems={navLinks} />
      </div>

      <span className="fixed-header-spacer" />
    </>
  );
}

type FullscreenNavLinkProps = {
  href: string;
  text: string;
  icon: ReactNode;
};

function FullscreenNavLink({ href, text, icon }: FullscreenNavLinkProps) {
  return (
    <li className="fs-nav-link">
      <Link prefetch="intent" to={href}>
        <span className="visually-hidden">{text}</span>
        <div className="fs-nav-link-wrapper">
          <div data-link-type={text} className="fs-nav-link-icon-wrapper">
            {icon}
          </div>
          {text !== "Home" && (
            <span data-link-type={text} className="fs-nav-link-text">
              {text}
            </span>
          )}
        </div>
      </Link>
    </li>
  );
}

function HeaderComponent({
  children,
  className,
  isHeaderSolidBackground,
  isHeaderVisible,
}: {
  className?: string;
  children: ReactNode;
  isHeaderVisible: boolean;
  isHeaderSolidBackground: boolean;
}) {
  return (
    <header
      className={`gn-header${className ? " " + className : ""}`}
      data-is-visible={isHeaderVisible}
      data-is-solid-background={isHeaderSolidBackground}
      data-transition-header={true}
    >
      {children}
    </header>
  );
}
