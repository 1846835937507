// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";

import styles from "~/styles/components/display/page-content/block-description.css";

BlockDescription.styles = styles;

export function BlockDescription({
  className,
  blockDescriptionData,
}: {
  className?: string;
  blockDescriptionData: any[];
}) {
  const DescriptionTextBlockRenderer = (props: any) => {
    const { style = "normal" } = props.node;

    if (style === "normal") {
      return (
        <>
          <p className={`block-description${className ? " " + className : ""}`}>
            {props.children}
          </p>
          <span className="block h-4"></span>
        </>
      );
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props);
  };

  return (
    <BlockContent
      blocks={blockDescriptionData[0]}
      serializers={{ types: { block: DescriptionTextBlockRenderer } }}
    />
  );
}
