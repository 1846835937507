import { BlockHeadline } from "./block-headline";
import { PreHeadlineText } from "./pre-headline-text";

ContentBlockHeadline.links = [
  { rel: "stylesheet", href: BlockHeadline.styles },
  { rel: "stylesheet", href: PreHeadlineText.styles },
];

export function ContentBlockHeadline({
  blockHeadlineSize,
  blockHeadlineClassName,
  blockHeadlineData,
  preHeadlineText,
  preHeadlineClassName,
}: ContentBlockHeadlineTypes) {
  return (
    <div className="w-full">
      <PreHeadlineText className={preHeadlineClassName}>
        {preHeadlineText}
      </PreHeadlineText>
      <BlockHeadline
        className={blockHeadlineClassName}
        blockHeadlineData={blockHeadlineData}
        hSize={blockHeadlineSize}
      />
      <span className="block h-4"></span>
    </div>
  );
}

type ContentBlockHeadlineTypes = {
  blockHeadlineSize?: 1 | 2 | 3 | 4 | 5 | 6;
  blockHeadlineClassName?: string;
  blockHeadlineData: any[];
  preHeadlineText: string;
  preHeadlineClassName?: string;
};
