export function DealDollarIcon({ className }: { className?: string }) {
  return (
    <svg
      className={className ? " " + className : ""}
      viewBox="0 0 2254 3496"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      aria-hidden="true"
      height="100%"
    >
      <path
        d="m1360.445 2577.339-1.16-565.03c269.73 45.93 389.7 333.82 212.58 488.16-41.73 36.37-145.9 80.74-211.42 76.87ZM977.382 744.811l.511 554.518c-141.446-43.56-272.443-114.44-281.112-276.3-9.269-173.122 112.825-265.753 280.601-278.218ZM962.83 187.228l-.755 44.323C519.331 293.779 103.17 513.119 103.17 1022.609c0 268.87 67.262 477.71 280.003 631.14 143.389 103.41 383.125 212.52 594.034 253.72l.806 652.03c-326.55-46.92-591.178-295.9-670.867-326.47L0 2693.389c55.061 73.47 316.92 201.72 426.415 248.93 171.338 73.87 334.478 105.28 534.071 143.94l2.629 409.26 416.86-.13.18-400.09c284.66-50.63 435.54-86.62 621.64-249.47 343.97-301 344.35-889.15-56.24-1192.63-120.95-91.63-292.77-159.6-444.79-209.26-190.04-62.06-141.82 58.13-141.78-530.291l.4-126.82c59.58-1.548 213.73 69.37 268.23 94.684l244.46 138.427c53.25-98.727 247.83-421.32 268.46-482.223-51.1-22.136-100.06-63.703-159.15-94.851-224.15-118.158-338.2-151.039-600.69-199.072L1380.715 0H962.769l.061 187.228Z"
        fill="inherit"
        fillRule="nonzero"
      />
    </svg>
  );
}
